<template>
  <v-modal name="coupon-dialog" @before-open="setCoupon" height="auto" width="840">
    <v-card :loading="isLoading">
      <v-card-title class="headline" primary-title>
        {{ isNewCoupon ? 'Kupon létrehozása' : 'Kupon szerkesztése' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-text-field v-model="coupon.name" label="Név" :rules="[requiredRule]"></v-text-field>
              <v-text-field v-model="coupon.coupon_code" label="Kupon kód" :rules="[requiredRule]"></v-text-field>
              <p>Kedvezmény típusa</p>
              <v-radio-group v-model="coupon.discount_type">
                <v-radio
                  v-for="item in couponDiscountTypes"
                  :key="item.id"
                  :label="item.label"
                  :value="item.type"
                ></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="coupon.discount_value"
                type="number"
                label="Kedvezmény értéke"
                :rules="[requiredRule]"
              ></v-text-field>
              <p>Lejárat típusa</p>
              <v-radio-group v-model="coupon.expiration_type">
                <v-radio
                  v-for="item in couponExpirationTypes"
                  @change="setCouponExpirationDate"
                  :key="item.id"
                  :label="item.label"
                  :value="item.type"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-select
                v-model="coupon.number_of_months"
                v-if="coupon.expiration_type === 'interval' || coupon.number_of_months"
                :hint="`pl.: ${monthSelect.label}`"
                :items="months"
                item-text="label"
                item-value="value"
                label="Hónapok száma"
                persistent-hint
                return-value
                single-line
              ></v-select>
              <v-date-picker
                v-if="coupon.expiration_type === 'date' && coupon.expiration_date"
                v-model="coupon.expiration_date"
                first-day-of-week="1"
              ></v-date-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="closeDialog">Bezárás</v-btn>
        <v-btn text color="primary" :loading="isLoading" @click="submit">Mentés</v-btn>
      </v-card-actions>
    </v-card>
  </v-modal>
</template>
<script>
import { diff } from 'deep-object-diff'
import moment from 'moment'

import { UPDATE_COUPON, CREATE_COUPON } from '@/store/types'
import formControl from '@/mixins/formControl'

const INITIAL_STATE = {
  name: ''
}

export default {
  name: 'CouponDialog',
  mixins: [formControl],
  data() {
    return {
      coupon: { ...INITIAL_STATE },
      editCoupon: {},
      isLoading: false,
      isNewCoupon: null,
      monthSelect: { label: '1 hónap', value: '1' },
      months: [
        { label: '1 hónap', value: 1 },
        { label: '2 hónap', value: 2 },
        { label: '3 hónap', value: 3 },
        { label: '4 hónap', value: 4 },
        { label: '5 hónap', value: 5 },
        { label: '6 hónap', value: 6 },
        { label: '7 hónap', value: 7 },
        { label: '8 hónap', value: 8 },
        { label: '9 hónap', value: 9 },
        { label: '10 hónap', value: 10 },
        { label: '11 hónap', value: 11 }
      ],
      couponExpirationTypes: [
        {
          id: 1,
          type: 'date',
          label: 'Dátum'
        },
        {
          id: 2,
          type: 'interval',
          label: 'Időtartam (hónapok száma)'
        }
      ],
      couponDiscountTypes: [
        {
          id: 1,
          type: 'fix',
          label: 'Fix összeg'
        },
        {
          id: 2,
          type: 'percent',
          label: 'Százalék'
        }
      ]
    }
  },
  methods: {
    setCoupon(event) {
      this.isNewCoupon = event.params.isNewCoupon
      this.coupon = event.params.coupon ? JSON.parse(JSON.stringify({ ...event.params.coupon })) : { ...INITIAL_STATE }

      if (!this.isNewCoupon) {
        this.editCoupon = JSON.parse(JSON.stringify({ ...event.params.coupon }))

        if (event.params.coupon.numberOfMonths) {
          this.monthSelect = {
            label: event.params.coupon.numberOfMonths + ' hónap',
            value: event.params.coupon.numberOfMonths
          }
        }
      }
    },
    closeDialog() {
      this.$modal.hide('coupon-dialog')
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    },
    setCouponExpirationDate() {
      if (!this.isNewCoupon && this.coupon.expiration_type !== 'date') {
        return
      }

      this.coupon.expiration_date = moment().format('YYYY-MM-DD')
    },
    async saveCoupon() {
      this.toggleLoading()

      try {
        await this.$store.dispatch(CREATE_COUPON, this.coupon)
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    async updateCoupon() {
      this.toggleLoading()

      try {
        const couponDiff = diff(this.editCoupon, this.coupon)
        await this.$store.dispatch(UPDATE_COUPON, { couponId: this.editCoupon.id, payload: couponDiff })
        this.closeDialog()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.toggleLoading()
      }
    },
    submit() {
      this.validate()

      if (!this.isValid) {
        return
      }

      if (this.isNewCoupon) {
        this.saveCoupon()
      } else {
        this.updateCoupon()
      }
    }
  }
}
</script>

<style lang="scss">
.v-card__subtitle {
  padding: 0;
  font-weight: 500;

  .theme--light & {
    color: #111 !important;
  }
}
</style>
